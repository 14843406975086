import CashbackApi from 'redux/modules/Cashback/api';
import { STORE_NAME, RESTRICTED_SELECTION_ERROR_MESSAGE } from './constants';
import {
  SERVICE_BROADBAND,
  SERVICE_HOMEPHONE,
  SERVICE_ENERGY,
  SERVICE_INSURANCE,
  SERVICE_MOBILE,
} from './services';
import { getSelectedQuote } from 'app/redux/modules/BillIncomeProtector/api';
import { ID_BILL_PROTECTOR } from 'app/redux/modules/BillIncomeProtector/constants';
import { FIXED_TARIFF_MINIMAL_SERVICES_AMOUNT } from 'redux/modules/Energy/constants';

const matchesProducts = (selection1, selection2) =>
  selection1.every((item) => selection2.includes(item)) &&
  selection2.every((item) => selection1.includes(item));

export class ServiceSelectionApi {
  static isEligibleForTariffChange(state) {
    return (
      ServiceSelectionApi.getSelectedServices(state)?.length >=
      FIXED_TARIFF_MINIMAL_SERVICES_AMOUNT
    );
  }

  static hasHomephone(state) {
    return state
      .getIn([STORE_NAME, 'selectedServices'])
      .toJS()
      .includes(SERVICE_HOMEPHONE);
  }

  static hasBroadband(state) {
    return state
      .getIn([STORE_NAME, 'selectedServices'])
      .toJS()
      .includes(SERVICE_BROADBAND);
  }

  static hasMobile(state) {
    return state
      .getIn([STORE_NAME, 'selectedServices'])
      .toJS()
      .includes(SERVICE_MOBILE);
  }

  static isEnergyOnly(state) {
    const services = ServiceSelectionApi.getSelectedServices(state);

    if (getSelectedQuote(state) === ID_BILL_PROTECTOR)
      services.push('billProtector');
    if (CashbackApi.getHasApplied(state)) services.push('cashback');

    return services.includes(SERVICE_ENERGY) && services.length === 1;
  }

  static hasSelectedServices(state) {
    return !!state.getIn([STORE_NAME, 'selectedServices']).count();
  }

  static getSelectedServices(state) {
    return state.getIn([STORE_NAME, 'selectedServices']).toJS();
  }

  static getConfirmServicesLoading(state) {
    return state.getIn([STORE_NAME, 'confirmServicesLoading']);
  }

  static getServicePreselect(state) {
    return state.getIn([STORE_NAME, 'preselect']).toJS();
  }

  static getRestrictionsLoading(state) {
    return state.getIn([STORE_NAME, 'restrictionsLoading']);
  }

  static getIsRestrictedBundleSelection(state, services) {
    const bundleRestrictions = state
      .getIn([STORE_NAME, 'restrictions', 'blockAtBundleScreen'])
      ?.toJS();

    return bundleRestrictions.some((serviceList) =>
      matchesProducts(serviceList, services)
    );
  }

  static getBundleConditions(state, services) {
    if (ServiceSelectionApi.getIsRestrictedBundleSelection(state, services)) {
      return { message: RESTRICTED_SELECTION_ERROR_MESSAGE, error: true };
    }

    // TODO deposit stuff goes here?
  }

  static getIsSubmissionRestricted(state) {
    const selectedServices = ServiceSelectionApi.getSelectedServices(state);
    const submitRestrictions = state
      .getIn([STORE_NAME, 'restrictions', 'blockAtApplicationSubmission'])
      ?.toJS();

    return submitRestrictions.some((serviceList) =>
      matchesProducts(serviceList, selectedServices)
    );
  }

  static getSelectedServicesAnalytics(state) {
    const services = [
      SERVICE_BROADBAND,
      SERVICE_MOBILE,
      SERVICE_ENERGY,
      SERVICE_INSURANCE,
    ];
    const selectedServices = ServiceSelectionApi.getSelectedServices(state);

    if (selectedServices.length === 0) {
      return {};
    }

    return services.reduce(
      (acc, v) => ({
        ...acc,
        [v]: selectedServices.includes(v),
      }),
      {}
    );
  }
}
