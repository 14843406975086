import { delay, fork, select, take, put } from 'redux-saga/effects';
import { APP_USER_LOGOUT } from 'redux/modules/App/constants';
import { DIALOG_ERROR_OPEN } from 'redux/modules/GlobalDialog/constants';
import { openGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import { startAgain } from 'redux/modules/App/actions';
import {
  SESSION_EXPIRED_MODAL_TITLE,
  SESSION_EXPIRED_MODAL_MESSAGE,
  WAIT_TIME,
} from './constants';

const excludedActions = [
  DIALOG_ERROR_OPEN,
  '@@router/LOCATION_CHANGE',
  APP_USER_LOGOUT,
];

export function* combinedSagas() {
  yield fork(sessionSaga);
}

export function* sessionSaga() {
  while (true) {
    const action = yield take();

    if (excludedActions.includes(action.type)) continue;

    const { TTL, timestamp } = yield select((state) => {
      const persistence = state.get('Persistence');

      return persistence ? persistence.toJS() : { timestamp: null };
    });

    if (timestamp && Date.now() >= timestamp + TTL) {
      yield put(
        openGlobalDialog(
          SESSION_EXPIRED_MODAL_TITLE,
          SESSION_EXPIRED_MODAL_MESSAGE
        )
      );
      yield delay(WAIT_TIME); // gives time for them to think about life
      yield put(startAgain());
    }
  }
}
