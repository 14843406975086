const SERVICE_HOMEPHONE = 'homephone';
const SERVICE_BROADBAND = 'broadband';
const SERVICE_MOBILE = 'mobile';
const SERVICE_ENERGY = 'energy';
const SERVICE_INSURANCE = 'insurance';

export {
  SERVICE_HOMEPHONE,
  SERVICE_BROADBAND,
  SERVICE_MOBILE,
  SERVICE_ENERGY,
  SERVICE_INSURANCE,
};
