import {
  SET_SERVICES,
  REMOVE_SERVICE,
  SERVICE_SELECTION_CONFIRM_SUCCESS,
  SERVICE_SELECTION_CONFIRM_FAILURE,
  ADD_UPSELL_SERVICES,
  SET_SERVICE_PRESELECT,
  BUNDLE_RESTRICTIONS_REQUEST,
  BUNDLE_RESTRICTIONS_SUCCESS,
  BUNDLE_RESTRICTIONS_FAILURE,
} from './constants';

export const confirmServicesSuccess = () => ({
  type: SERVICE_SELECTION_CONFIRM_SUCCESS,
});
//TODO: handle the error state on the UI side - not connected to the redux
export const confirmServicesFailure = () => ({
  type: SERVICE_SELECTION_CONFIRM_FAILURE,
});

export const addUpsellServices = (services) => ({
  type: ADD_UPSELL_SERVICES,
  services,
});

export const setServices = (serviceIds, forceNoDaffodil = false) => ({
  type: SET_SERVICES,
  serviceIds,
  forceNoDaffodil,
});

export const removeService = (serviceId) => {
  const serviceIds = [serviceId];
  return {
    type: REMOVE_SERVICE,
    serviceIds,
  };
};

export const setServicePreselect = (services) => ({
  type: SET_SERVICE_PRESELECT,
  services,
});

export const bundleRestrictionsRequest = () => ({
  type: BUNDLE_RESTRICTIONS_REQUEST,
});

export const bundleRestrictionsSuccess = (restrictions) => ({
  type: BUNDLE_RESTRICTIONS_SUCCESS,
  restrictions,
});

export const bundleRestrictionsFailure = (error) => ({
  type: BUNDLE_RESTRICTIONS_FAILURE,
  error,
});
