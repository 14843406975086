import { call, put, select, takeLatest } from 'redux-saga/effects';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { triggerEligibilityChecks } from 'redux/modules/Eligibility/actions';
import { exclusionParams } from 'redux/modules/Eligibility/constants';
import { updatedSelectedServices } from './service/updateSelectedServices';
import { ApplicationApi } from '../App/api';
import { PartnerLoginApi } from '../PartnerLogin/api';
import { ServiceSelectionApi } from './api';
import * as actions from './actions';
import * as types from './constants';
import { openErrorGlobalDialog } from 'app/redux/modules/GlobalDialog/actions';
import getBundleRestrictions from './service/getBundleRestrictions';

export function* setServices(serviceIds) {
  const [
    applicationId,
    token,
    remotePositionId,
    localPositionId,
    exclude,
  ] = yield select((state) => [
    ApplicationApi.getCurrentApplication(state),
    ApplicationApi.getToken(state),
    ApplicationApi.getRemotePartnerId(state),
    PartnerLoginApi.getPartnerId(state),
    ApplicationApi.getExcludeParams(state),
  ]);

  const positionId = remotePositionId || localPositionId;

  const insuranceExcluded =
    exclude.includes(exclusionParams.BOILER) &&
    exclude.includes(exclusionParams.BILL_INCOME_PROTECTOR);

  // deselect insurance if both services (boiler and BIP) are excluded
  // which will prevent routing issues when navigating back
  // from the details pages
  const updatedServiceIds = serviceIds.filter((id) => {
    if (id === 'insurance' && insuranceExcluded) return false;
    return true;
  });

  try {
    yield call(
      updatedSelectedServices,
      applicationId,
      token,
      updatedServiceIds,
      positionId
    );

    yield put(triggerEligibilityChecks());
    yield put(actions.setServices(updatedServiceIds));
  } catch (err) {
    yield put(actions.confirmServicesFailure());
    Sentry.log(err, ERRORS.UPDATE_REQUESTED_SERVICES);
    throw err;
  }
}

export function* sendUpsellServices({ services }) {
  const currentServices = yield select(ServiceSelectionApi.getSelectedServices);
  const serviceIds = [...new Set([...currentServices, ...services])];
  yield call(setServices, serviceIds);
}

export function* removeServiceFromBundles({ serviceIds }) {
  const currentServices = yield select(ServiceSelectionApi.getSelectedServices);
  const updatedServices = currentServices.filter(
    (service) => !serviceIds.includes(service)
  );
  yield call(setServices, updatedServices);
}

function* getBundleRestrictionsRequest() {
  try {
    const response = yield call(getBundleRestrictions);
    const restrictions = response?.data;
    yield put(actions.bundleRestrictionsSuccess(restrictions));
  } catch (error) {
    Sentry.log(error, ERRORS.GET_BUNDLE_RESTRICTIONS);
    const message = `We encountered an error while fetching the service prices. ${error}`;
    yield put(openErrorGlobalDialog(message));
    yield put(actions.bundleRestrictionsFailure(error));
  }
}

export function* combinedSagas() {
  // update selected services remote state
  yield takeLatest(types.ADD_UPSELL_SERVICES, sendUpsellServices);
  yield takeLatest(types.REMOVE_SERVICE, removeServiceFromBundles);
  yield takeLatest(
    types.BUNDLE_RESTRICTIONS_REQUEST,
    getBundleRestrictionsRequest
  );
}
