import { jtcRequest, METHOD } from 'redux/utils/request';

export const updatedSelectedServices = (
  applicationId,
  applicationToken,
  services,
  positionId
) =>
  jtcRequest(
    METHOD.PUT,
    `application/${applicationId}/requested-services`,
    { services },
    {
      applicationToken,
      positionId,
    }
  );
