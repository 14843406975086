export const STORE_NAME = 'ServiceSelection';

// types
export const SET_SERVICE_PRESELECT = 'SET_SERVICE_PRESELECT';
export const SET_SERVICES = 'SET_SERVICES';
export const REMOVE_SERVICE = 'REMOVE_SERVICE';
export const SERVICE_SELECTION_CONFIRM_SUCCESS =
  'SERVICE_SELECTION_CONFIRM_SUCCESS';
export const SERVICE_SELECTION_CONFIRM_FAILURE =
  'SERVICE_SELECTION_CONFIRM_FAILURE';

export const ADD_UPSELL_SERVICES = 'ADD_UPSELL_SERVICES';

export const BUNDLE_RESTRICTIONS_REQUEST = 'BUNDLE_RESTRICTIONS_REQUEST';
export const BUNDLE_RESTRICTIONS_SUCCESS = 'BUNDLE_RESTRICTIONS_SUCCESS';
export const BUNDLE_RESTRICTIONS_FAILURE = 'BUNDLE_RESTRICTIONS_FAILURE';

export const RESTRICTED_SELECTION_ERROR_MESSAGE =
  'Add another service to continue';
