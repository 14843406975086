import Immutable from 'immutable';
import * as types from './constants';

const initialState = Immutable.fromJS({
  selectedServices: [],
  confirmServicesLoading: false,
  preselect: {
    active: false,
    services: [],
  },
  restrictions: {
    blockAtApplicationSubmission: [],
    blockAtBundleScreen: [],
  },
  restrictionsLoading: false,
});

export default function ServiceSelectionReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_SERVICE_PRESELECT:
      return state.merge({
        preselect: {
          active: true,
          services: action.services,
        },
      });
    case types.SET_SERVICES: {
      return state.merge({
        selectedServices: action.serviceIds,
        confirmServicesLoading: true,
      });
    }
    case types.ADD_UPSELL_SERVICES: {
      let newUpsellServices = state.get('selectedServices').toJS();
      action.services.forEach((service) => {
        if (service === 'homephone') {
          newUpsellServices.homephone = true;
        } else {
          newUpsellServices[service] = true;
        }
      });
      return state.mergeDeep({
        selectedServices: newUpsellServices,
      });
    }

    case types.REMOVE_SERVICE: {
      return state.merge({
        selectedServices: state
          .get('selectedServices')
          .filter((s) => !action.serviceIds.includes(s)),
      });
    }

    case types.SERVICE_SELECTION_CONFIRM_SUCCESS:
      return state.set('confirmServicesLoading', false);

    case types.SERVICE_SELECTION_CONFIRM_FAILURE:
      return state.set('confirmServicesLoading', false);

    case types.BUNDLE_RESTRICTIONS_REQUEST:
      return state.set('restrictionsLoading', true);

    case types.BUNDLE_RESTRICTIONS_SUCCESS:
      return state.merge({
        restrictions: action.restrictions,
        restrictionsLoading: false,
      });

    case types.BUNDLE_RESTRICTIONS_FAILURE:
      return state.set('restrictionsLoading', false);

    default:
      return state;
  }
}
